<template>
<div>
    <AppSubHeader link='contratos' title='Mis Contratos'/>
<main class="m-content container mb-5">

<div class="row">
	<div class="col-xl-6 mx-auto">
		<DocumentWidget />
	</div>
</div>

</main>
</div>
</template>

<script>

import AppSubHeader from "@/components/layout/AppSubHeader.vue";
import DocumentWidget from "@/components/document/DocumentWidget.vue";

export default {
  name:'Document',
  components:{
      AppSubHeader,
	  DocumentWidget,
	},
}

</script>